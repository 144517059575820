

import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ComplexSelect from '@/modules/common/components/ui-kit/complex-select.vue';
import { COMPARE_KEY, COMPARE_VALUES, COMPARE_NAMES } from '../../consts/compare-filter-values';
import PromotionsService, { PromotionsServiceS } from '../../promotions.service';

@Component({
    components: {
        ComplexSelect,
    },
})
export default class CompareFiltersBar extends Vue {
    @Inject(PromotionsServiceS) private promotionsService!: PromotionsService;

    get value() {
        const { key, value } = this.promotionsService.comparisonFilter;
        return [key, value];
    }

    set value([key, value]: [COMPARE_KEY, string]) {
        this.promotionsService.comparisonFilter = {
            key,
            value,
        };
    }

    get items() {
        const keyItems = (Object.keys(COMPARE_VALUES) as COMPARE_KEY[]).map(key => ({
            name: this.$tc(COMPARE_NAMES[key]),
            value: key as string,
        })) as Item[];
        const valueItems = Object.values(COMPARE_VALUES).map(values => values.map(v => ({
            ...v,
            name: this.$tc(v.name),
        })));
        return [keyItems, valueItems];
    }
}
