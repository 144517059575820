
import { Inject } from 'inversify-props';
import { Component } from 'vue-property-decorator';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import PosDocumentFilter from '@/modules/document-filters/components/pos-document-filter.vue';
import LosDocumentFilter from '@/modules/document-filters/components/los-document-filter.vue';
import AnalysisBtn from '@/modules/common/components/ui-kit/analysis-btn.vue';
import PromotionsFiltersMixin from '../mixins/promotions-filter.mixin';
import { PromotionGraphType } from '../models/promotions-settings.model';

@Component({
    components: {
        CustomSelect,
        PosDocumentFilter,
        LosDocumentFilter,
        CustomMultiSelect,
        AnalysisBtn,
    },

    extends: PromotionsFiltersMixin,
})
export default class PromotionsActions extends PromotionsFiltersMixin {
    @Inject(HelperServiceS)
    public helperService!: HelperService;

    get graphType() {
        return this.promotionsService.graphType;
    }

    set graphType(value: PromotionGraphType) {
        this.promotionsService.graphType = value;
    }

    get isGraphView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('.graph');
    }

    get isTableView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('.table');
    }

    get isAnalysisView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('.analysis');
    }

    get isMainView() {
        return !this.isGraphView && !this.isTableView && !this.isAnalysisView;
    }

    get isLoading() {
        return this.promotionsService.isLoading;
    }

    get currentView() {
        if (!this.$route.name) return '';
        return this.$route.name.split('.').pop();
    }

    get rootRouteName() {
        const branch = this.$route.path!.split('/')[1];
        const name = `${branch}.promotions`;

        switch (branch) {
            case 'cluster':
                return `${name}.hotel`;

            case 'chain':
                return `${name}.hotel`;
                // NOTE: uncomment once chain level is implemented
                // return `${name}.cluster.hotel`;

            default:
                return name;
        }
    }

    get graphRoute() {
        return {
            name: `${this.rootRouteName}.graph`,
            params: {
                ...this.$route.params,
            },
        };
    }

    get mainRoute() {
        return {
            name: this.rootRouteName,
            params: {
                ...this.$route.params,
            },
        };
    }

    get tableRoute() {
        return {
            name: `${this.rootRouteName}.table`,
            params: {
                ...this.$route.params,
            },
        };
    }
}
