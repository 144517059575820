
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import PromotionsHeader from '../components/promotions-header.vue';
import PromotionsMarkTable from '../components/promotions-mark-table.vue';
import PromotionsActions from '../components/promotions-actions.vue';
import PromotionsGraph from '../components/promotions-graph.vue';
import PromotionsTable from '../components/promotions-table.vue';
import PromotionsAnalysisTable from '../components/promotions-analysis-table';
import PromotionsService, { PromotionsServiceS } from '../promotions.service';

@Component({
    components: {
        PromotionsHeader,
        PromotionsMarkTable,
        PromotionsActions,
        PromotionsGraph,
        PromotionsTable,
        PromotionsAnalysisTable,
        PageWrapper,
        Dialog,
    },
})
export default class Promotions extends Vue {
    @Inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    get isGraphView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('.graph');
    }

    get isTableView() {
        if (!this.$route.name) return false;
        if (this.isAnalysisTableView) return false;
        return this.$route.name!.includes('.table') && this.$route.name!.includes('hotel');
    }

    get isMainView() {
        return !this.isGraphView && !this.isTableView && !this.isAnalysisTableView;
    }

    get isAnalysisTableView() {
        if (!this.$route.name) return false;
        return this.$route.name!.includes('.analysis.table');
    }

    get isNoData() {
        return this.promotionsService.isNoData;
    }
}
