import { Component, Mixins } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import PromotionsService, { PromotionsServiceS } from '@/modules/promotions/promotions.service';
import BaseOtelMixin from '../common/base-otel.mixin';
import { LOGTYPE } from '../../constants';

/**
 * Mixin for promotion hotel pages.
 * Open OTEL span on before component mount and end it, on finishing document loading
 * (it doesn't take in count time for rerender component after document update).
 */
@Component
export default class PromotionsPageOtelLogs extends Mixins(BaseOtelMixin) {
    @Inject(DocumentFiltersServiceS) documentFiltersService!: DocumentFiltersService;
    @Inject(PromotionsServiceS) promotionsService!: PromotionsService;

    static readonly spanPrefix = LOGTYPE.LOADING;

    beforeMount() {
        this.startSpan(PromotionsPageOtelLogs.spanPrefix);
    }

    mounted() {
        this.addEvent('mounted', PromotionsPageOtelLogs.spanPrefix);
        this.promotionsService.loading.whenLoadingFinished()
            .then(_ => {
                this.addEvent('gotRespnose', PromotionsPageOtelLogs.spanPrefix);
                this.endSpan(PromotionsPageOtelLogs.spanPrefix, { sendLogs: true, payload: this.payload });
            });
    }

    beforeDestroy() {
        this.endSpan(PromotionsPageOtelLogs.spanPrefix);
    }

    private get payload() {
        const settingsUnion = {
            ...this.promotionsService.settings,
            ...this.documentFiltersService.settings,
        } as Record<string, any>;

        return {
            'cx.action.filterBy': this.otelService.buildFilterBy(settingsUnion),
        };
    }
}
