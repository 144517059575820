
import { Inject } from 'inversify-props';
import { Component, Mixins } from 'vue-property-decorator';

import ClipText from '@/modules/common/filters/clip-text.filter';
import DragScrollMixin from '@/modules/common/mixins/drag-scroll.mixin';
import PromotionsOtelMixin from '@/modules/open-telemetry/mixins/promotions/promotions-page-otel-logs.mixin';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import moment from 'moment';

@Component({
    filters: {
        ClipText(value: string) {
            return ClipText(value, 30);
        },
    },
})
export default class PromotionsTable extends Mixins(DragScrollMixin, PromotionsOtelMixin) {
    @Inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    averageValues = {
        deals: [] as number[],
        programs: [] as number[],
    };

    get isNoData() {
        if (this.isLoading) return false;
        if (!this.promotionsService.data) return true;

        return !Object.keys(this.promotionsService.data.providerData).length;
    }

    private get mainHotelId() {
        return +this.$route.params.hotelId;
    }

    get mainHotelLabel() {
        return this.hotelsService.getHotelName(this.mainHotelId);
    }

    get isLoading() {
        return this.promotionsService.isLoading;
    }

    get dummyData() {
        const { days } = this.documentFiltersService;

        return days
            .map(__ => Array
                .from({ length: 7 })
                .map(_ => ({
                    dealProgramsCount: '99',
                    defaultProgramsCount: '99',
                })));
    }

    public get tableData() {
        const { days } = this.documentFiltersService;
        const { provider, data } = this.promotionsService;
        const { competitors } = this.documentFiltersService;

        if (this.isLoading) {
            return this.dummyData;
        }

        if (!data || !provider) return [];

        const averageDeals = [] as number[];
        const averagePrograms = [] as number[];

        const rowsData = days.map(day => {
            let averageDealCount = 0;
            let averageProgramsCount = 0;
            const competitorsCount = competitors?.length || 0;

            const hotelsData = competitors?.map(hotelId => {
                const p = this.promotionsService.getPrograms(day, provider, hotelId);

                if (p === null) {
                    return {
                        hotelId,
                        day,
                        dealsCount: 0,
                        programsCount: 0,
                        dealsStatus: 'grey',
                        programStatus: 'grey',
                    };
                }

                const programs = Object.values(p);
                const dealsCount = programs
                    .filter(program => ('percentage' in program) && program.status).length;
                const programsCount = programs
                    .filter(program => !('percentage' in program) && program.status).length;

                averageDealCount += dealsCount;
                averageProgramsCount += programsCount;

                return {
                    hotelId,
                    day,
                    dealsCount,
                    programsCount,
                    programStatus: programsCount > 0 ? undefined : 'grey',
                    dealsStatus: dealsCount > 0 ? undefined : 'grey',
                };
            });

            averageDeals.push(averageDealCount / competitorsCount);
            averagePrograms.push(averageProgramsCount / competitorsCount);

            return hotelsData;
        });

        this.averageValues.deals = averageDeals;
        this.averageValues.programs = averagePrograms;

        return rowsData;
    }

    public get mainData() {
        const { days, year, month } = this.documentFiltersService;
        const { provider } = this.promotionsService;
        // const dateFormat = new Intl.DateTimeFormat(this.$i18n.locale, { weekday: 'short', month: 'numeric', day: '2-digit' });

        if (!provider) return [];

        return days.map((day, index) => {
            const programs = Object.values(
                this.promotionsService
                    .getPrograms(day, provider, this.mainHotelId) || {},
            );

            const dealsCount = programs
                .filter(program => ('percentage' in program) && program.status).length;
            const programsCount = programs
                .filter(program => !('percentage' in program) && program.status).length;

            const averageDeal = this.averageValues.deals[index];
            const averageProgram = this.averageValues.programs[index];

            let dealsStatus: string | undefined = 'yellow';
            let programStatus: string | undefined = 'yellow';

            if (averageDeal !== dealsCount) {
                dealsStatus = averageDeal > dealsCount
                    ? 'red'
                    : 'green';
            }

            if (averageProgram !== programsCount) {
                programStatus = averageProgram > programsCount
                    ? 'red'
                    : 'green';
            }

            const isNa = !Object.keys(programs).length;

            if (isNa) {
                dealsStatus = 'grey';
                programStatus = 'grey';
            }

            return {
                hotelId: this.mainHotelId,
                day,
                dealsCount: !isNa ? dealsCount : 0,
                programsCount: !isNa ? programsCount : 0,
                date: moment(new Date(year, month, day)).format('ddd DD/MM'),
                dealsStatus,
                programStatus,
            };
        });
    }

    public get hotelList() {
        const { competitors } = this.documentFiltersService;

        if (this.isLoading) return Array.from({ length: 7 }).map(_ => '_____________');
        if (!competitors) return [];

        return competitors
            .map(hotelId => this.hotelsService.getHotelName(hotelId));
    }

    public openPopup(day: number) {
        if (this.isDragging()) return;

        this.$router.push({
            name: `${this.$route.name}.popup`,
            params: {
                ...this.$route.params,
                day: String(day),
            },
        });
    }
}
